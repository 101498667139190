export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAXkX3XmzG6gH_zhnOjX5kOX8Bqs3R3cps',
    authDomain: 'lisackepredplatne.cz',
    databaseURL: 'https://loyal-fox-915f4.firebaseio.com',
    projectId: 'loyal-fox-915f4',
    storageBucket: 'loyal-fox-915f4.appspot.com',
    locationId: 'europe-west',
    messagingSenderId: '642531247048',
    appId: '1:642531247048:web:ece2f14742fe23769776fd',
  },
  functionsUrl: 'https://europe-west1-loyal-fox-915f4.cloudfunctions.net/',
  useEmulators: false,
  name: 'Production',
  hotjarId: '3462109',
  hostName: 'https://lisackepredplatne.cz',
};
